@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

*{margin:0;padding: 0;box-sizing: border-box;}
body{font-size:13px; background-color: #eeeeee; font-family: 'Rubik', sans-serif; line-height: 1.4; cursor: default;}
#root{font-size:13px; background-color: #eeeeee; font-family: 'Rubik', sans-serif; line-height: 1.4; width: 100%; float: left;}
img{max-width: 100%;display: block;}
button, input, textarea {font-family: 'Rubik', sans-serif; font-size: 14px; outline: none;}
.container{width: 100%; margin: 0 auto; max-width: 1300px;}

/*------------------------Bootstrap CSS Change---------------------------*/

a{color: #eb2330;}
a:hover{text-decoration: none;}
.form-control {font-size:14px; background-color:#ffffff; border:none; border: 1px solid #a3a3a3; border-radius: 2px; padding:11px; width: 100%; outline: none;}
.form-control:focus{border-color: red;}
select.form-control{border:0;  background:url(../images/arrow.png) no-repeat right center;}
.btn-primary{background-color: #ea2330; border-color: #ea2330; border-radius: 4px;}
.btn{padding:9px 18px; font-size:15px;}
.form-check{line-height: 1.6;    margin: 15px auto;}
label{font-weight: 500; margin-right: 1px;}
.form-check-label {
  line-height: 1.9;
  margin: 1px 12px 0px;
  font-size: 15px;
  font-weight: 300;
}
.ml-auto .btn-link{
  font-size: -15px;
  border: none;
  font-weight: 600;
  color: #e81c1c;
  cursor: pointer;
  padding: 9px 18px;
  font-size: 15px;
}
.ml-auto .btn-primary{
  border: none;
  background: #d71c1c;
  color: white;
  cursor: pointer;
  font-size: 15px;
}
.ml-auto {float: right;}
/*------------------------Dashboard form area---------------------------*/

.header{background-color: #3D3D3D;float: left;width: 100%;padding:0px; box-shadow:0 0 5px rgba(0,0,0,.3); background-color: #fff;padding: 10px 50px; margin-bottom: 10px;}
.width_size{max-width: 1300px !important}
.logo{display: inline-block;}
.app-login-box  .logo {
  width: 13%;
  margin: 61px 78px 37px;
  border-radius: 153px;
  overflow: hidden;
}
.logo h1{font-size: 36px; font-weight: 700; color: #eb2330;}
.logo h1 span{color:#6d6e72}
.right_header_area{float: right; padding: 4px 0 0;}
.right_header_area>ul{float: left;}
.right_header_area>ul>li{list-style: none;float: left;color: #fff;padding: 0 20px;margin-top: 5px;}
.right_header_area>ul>li.user_name{border-left: 2px solid #fff;position: relative;min-width: 100px;padding-right: 0}
.right_header_area>ul>li.user_name select{background-color:#3D3D3D;border:none;color: #fff;width: 100%}
.right_header_area>ul>li.user_name:after{content: '';position: absolute;top:4px;right:8px;height: 10px;width: 10px;transform: rotate(45deg);border-right:2px solid #fff;border-bottom: 2px solid #fff}
.right_header_area>ul>li.bell_icon a{color: #fff;padding-top:2px;}
.right_header_area>ul>li.user_pic{height: 38px;width: 77px;margin-top: 0;}
.right_header_area>ul>li.user_pic img{max-height:100px ;border-radius: 50px;border:2px solid #fff;}

.left_area{width: 20%;float: left;box-shadow:5px 0 6px -8px #000;z-index: 100;position: relative;font-size: 13px}
.left_area>ul>li{list-style:none;padding: 8px 0;width: 100%;padding: 12px 10px 12px 15px;border-left: 3px solid #fff;}
.left_area>ul>li>a:after{content: '';position: absolute;top:4px;right:14px;height: 8px;width: 8px;border-bottom: 1px solid #AAAAAA;border-right: 1px solid #AAAAAA;transform: rotate(45deg);}
.left_area>ul>li>a{text-decoration: none;color: #000;display: block;position: relative;}
.left_area>ul>li:hover{border-left: 3px solid #000;background-color: #F9F9F9}
.left_area>ul>li:hover{border-left: 3px solid #000;background-color: #F9F9F9;}
.left_area>ul>li a:hover:after{border-top:1px solid #AAAAAA;border-bottom: none; }
.left_area>ul>li>a:hover{color: #ff0000}

.flightDropdown{position: absolute; width: 100%; background-color: #ffffff;}
.flightDropdown ul li{padding:10px;}
.search_area{position: relative;}
.spinner {
    margin: 150px auto 0;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }

  .spinner > div {
    background-color: #eb2330; margin:0 1px;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;


  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% {
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }

.tr_plan{background-color:#F0F0F7;}
.right_area{
  width: 100%; clear: both;
  max-width: 1200px; margin: 0 auto;
  border-radius: 4px;
  padding: 0px;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  background-color: #fff;
}
.right_area .uper_area{float: left;margin-top: 10px;width: 100%}
.right_area .uper_area li{list-style: none;float: left;}
.plan_name{font-size: 20px;color: rgb(65, 64, 64);position: relative;width: 18%;margin-top: 10px}
.plan_name:after{content: '';position: absolute;top: 9px;right: 11px;height: 19px;width: 2px;background-color: #3D3D3D}
.plan_doc{font-size: 14px;width: 10%; font-weight: 700;margin-top: 19px}
.plan_doc a{color: #1F88D6;}
.search_btn{width: 30%;margin-left: 10%}
.uper_area .form-control,.uper_area .btn{border-radius: 20px;}
.action_btn{width: 100%}
.download{width: 20%;margin-left: 1%;position: relative;overflow: hidden;}
.download input[type=file]{opacity: 0;position: absolute;top: 0;left: 0;}
.upload{width: 10%;margin-left: 1%; position: relative; overflow: hidden;}
.uper_area>ul>li>a{text-decoration: none;color: #fff;background-color: #ff0000;position: relative; padding: 8px 10px 8px 44px;font-size: 12px; border-radius: 20px;margin-left: 20px;font-weight: 700}
.upload input[type=file] {opacity: 0; position: absolute; top: 0; left: 0;padding: 8px}


.table{float: left;box-shadow: 0 0 7px 0 rgb(216, 213, 213);background-color: #fff;color: #3D3D3D;border-radius: 10px}
.table ul.table_detail{float: left;width: 100%;margin-bottom: 0;padding:20px 5px;}
.table ul.table_header{font-size: 17px;color: rgb(66, 66, 66);}
.table ul.col_grey{background-color:#F9F9F9;border-top:1px solid #ccc;}
.table_detail li{list-style: none;float: left;text-align: center;width: 20%;color:rgb(102, 101, 101);font-weight: 500;}
.table_detail li.header_row{color: rgb(66, 66, 66);padding-left: 30px;text-align: left;}
.table_detail li.link_text{padding-left: 30px;text-align: left !important}
.link_text a{color: #0DB42A;text-decoration: none;}
.action_link{float: left;width:65%;margin:0 17.5%;}
.action_link li{width: 33.33%;list-style: none;float: left;}
.trash_icon{color:#F85A5A }
.edit_icon{color:#1F88D6 }
.download_icon{color:#707070 }

/*------------------------form area---------------------------*/

.col_black{background-color: rgba(0,0,0,0.5);float:left;position:fixed;top: 0;left: 0;z-index: 999;width: 100%;height: 100%;display: none;}
.form_container{max-width: 1100px;width: 100%;position: absolute;z-index: 999;left: 50%;margin-left: -550px}
.form_area{float: left;background-color: #fff;margin-top: 100px;text-align: center;padding:30px 15px;border-radius: 10px;color: #9f9f9f;}
.close_pop{float: right;}
.close_pop a{position: relative;top: -27px;height: 20px;width: 20px;display: inline-block;font-size: 0}
.close_pop a:before{content: '';position: absolute;top: 0;right:10px;height:15px;width: 2px;background-color: #000;transform: rotate(45deg);}
.close_pop a:after{content: '';position: absolute;top: 0;right:10px;height:15px;width: 2px;background-color: #000;transform: rotate(-45deg);}
.form_area h1{color: #000;font-size: 20px;padding: 20px 0;font-weight: 600}
.form_area form ul{width: 100%;float: left;text-align: left;}
.form_area form ul li{width: 29.33%;margin:10px 2%;float: left;list-style: none;}
.form_area form ul li input.form-control:focus{background-color: #ededed;box-shadow: none;border-color: #ccc}

.form_area form button{margin-top:20px}
.form_area form button.btn-success{background: linear-gradient(to right, #ff0000 0%, #800000 100%);box-shadow: none;border: 1px solid #ff0000;}
.form_area form button.btn-success:hover{background: linear-gradient(to left, #ff0000 0%, #800000 100%);}
form ul li select.my-1:focus, form ul li select.mr-sm-2:focus{border-color: #ccc;background-color: #ededed;box-shadow: none;}

.custom-select{margin:0;border-radius: 20px}
form ul li select.my-1,form ul li select.mr-sm-2{margin-top: 0!important;margin-bottom: 0!important}
.submit{min-width: 120px;margin:0 10px;}

/*------------------------Sign-in form area---------------------------*/

.sign_form_container{max-width: 490px;margin:100px auto 20px;width: 100%}
.sign_form{border-radius: 8px;box-shadow: 0 0 5px 0 #ccc;float: left;padding:50px 25px;color: #9f9f9f;text-align: center;}
.sign_form h1{color: #000;font-size: 20px;text-align: left;}
.sign_form form ul{width: 100%;text-align: left;}
.sign_form form ul li{width: 100%;list-style: none;}
.sign_form form ul li .keep_sign{float: left;}
.sign_form form ul li .for_got{float: right;}
.sign_form form ul li .for_got a{color: #ff0000}
.sign_form form button.btn-success{background: linear-gradient(to right, #ff0000 0%, #800000 100%);box-shadow: none;border: 1px solid #ff0000;}
.sign_form form button.btn-success:hover{background: linear-gradient(to left, #ff0000 0%, #800000 100%);}

/*------------------------Sign-in form area---------------------------*/

.back_col_grey{background-color: #E8E8E8;padding: 30px}
.product_page h1{font-size: 15px;padding: 11px;border:1px solid #666;background-color: #dadada;color: #454545}
.product_page .btn,.product_page .form-control{border-radius: 2px}
.product_page h1 span{color: #ff0000}
.product_page h1 span:hover{color: #000}
.product_page form ul.search_area{float: right;}
ul.search_area .form-group label{width: 30%;float: left;padding:9px 0 0 10px}
ul.search_area .form-group input{width: 70%;float: left;}
.action_btn{clear: both;}
.product_page form ul li{margin: 0;float: left;list-style: none;}
form ul.search_area li:nth-child(3){width: 12%}
.product_page form .form-group{margin-bottom: 0}

/*------------------------Flight Search form area---------------------------*/

.journey_type label{padding-right: 15px; padding-left: 5px; font-weight: 400; font-size: 14px;}
.journey_type{padding-bottom: 10px;}

.head_tab{  border-bottom:1px solid #e0e0e2; padding: 5px;}
.head_tab li{list-style: none;display: inline-block;}
.head_tab li a{color: #cbcbcc;text-decoration: none;font-weight: 700;padding: 6px 10px; display: block;}
.head_tab li a span{color: #fff;background-color: #cbcbcc;padding:1px 7px;border-radius: 2px; display: inline-block; margin-right: 5px;}
.head_tab li a:hover{color: #eb2330}
.head_tab li a:hover span{background-color:#eb2330 }
.head_tab li a.active span{background-color: #eb2330;}

.flight_search{padding:30px;width:100%; position:relative}
.loading{position:absolute; top:0; left:0; background-color:rgba(255,255,255,0.9); width:100%; height:100%; z-index:9999; text-align:center}
.flight_search>form>button.btn{float: right;}
.detail_head li{list-style:none;display:inline-block; margin-right:10px }
.detail_head li a{color:#666;text-decoration: none;font-weight: 600; padding: 5px 24px;border:1px solid #ccc;border-radius: 4px; display: block; }
.detail_head li a:hover{color: #fff; background-color: #eb2330; border-color: #eb2330 ;}




.sub_area > ul > li{width: 49%;list-style: none;display: inline-block;margin: 6px 2% 15px 0; vertical-align: top; position: relative;}
.sub_area > ul > li:nth-child(2) {margin: 6px 0 15px 0;display: inline-block;}
.sub_area > ul > li:nth-child(3) {margin: 6px 0 15px 0;display: inline-block}
.sub_area > ul > li:nth-child(4) {margin: 6px 0 15px 2%;}

.sub_area>ul:nth-of-type(1)>li:nth-of-type(1) input,.sub_area>ul:nth-of-type(1)>li:nth-of-type(2) input{
  border: 2px solid #a3a3a3;
}

/* styling mui component */

 
.sub_area>ul:nth-of-type(1)>li:nth-of-type(3)>.datePicker> div > div > div > div > fieldset, .sub_area>ul:nth-of-type(1)>li:nth-of-type(4)>.datePicker> div > div > div > div > fieldset{border:2px solid #a3a3a3;border-radius: 2px;} 
.sub_area>ul:nth-of-type(1)>li:nth-of-type(3)>.datePicker> div > div > div > div > input:focus ~ fieldset,.sub_area>ul:nth-of-type(1)>li:nth-of-type(4)>.datePicker> div > div > div > div > input:focus ~ fieldset{border-color: #eb2330;}


.datePicker> div > div{width:100%}
.datePicker> div > div > p{width: 100%;
  font-size: 13px;
  display: block;
  text-transform: capitalize;
  font-weight: 500;
}








.sub_area>ul:nth-of-type(1)>li:nth-of-type(1) input:focus,.sub_area>ul:nth-of-type(1)>li:nth-of-type(2) input:focus{border-color: #eb2330;}

.sub_area>ul:nth-of-type(1)>li:nth-of-type(3) label,.sub_area>ul:nth-of-type(1)>li:nth-of-type(4) label{display: block;} 
/* .sub_area>ul:nth-of-type(1)>li:nth-of-type(3) input,.sub_area>ul:nth-of-type(1)>li:nth-of-type(4) input{display: inline-block;width: 60%} */
.sub_area>ul:nth-of-type(1)>li:nth-of-type(3) select,.sub_area>ul:nth-of-type(1)>li:nth-of-type(4) select{display: inline-block;width: 39%} 
.sub_area>ul:nth-of-type(2){margin-top:0.7%}
.sub_area>ul:nth-of-type(2)>li{list-style: none;display: inline-block;width: 16%;margin:5px 10px 0 0;}
.sub_area>a{text-decoration: none;}
ul.preferred_carrier>li{width: 45%;list-style: none;display: inline-block;vertical-align: top;margin: 10px 20px}
.ristrict_points>ul{border:1px solid #e0e0e2;background-color: #fbfbfb;padding: 20px}
.ristrict_points h3{color: #eb2330;font-size: 20px;font-weight: 600;}
.ristrict_points>ul>li{list-style: none;display: inline-block;width: 30%;margin: 5px 0 5px 3%;}


.return_types .form-check{display: inline-block;width: 25%;margin:10px 0;}

/*------------------------Flight result form area---------------------------*/

.flight_results>ul:nth-of-type(1){background-color: #ffffff;float: left;width: 100%;border-radius: 4px;margin:5px 0;}
.flight_results>ul:nth-of-type(1)>li{float: left;list-style: none;padding: 6px 10px;font-weight: 600;}
.flight_results>ul:nth-of-type(1)>li:nth-of-type(1){width: 20%;background-color: #e0dedf;border-radius: 4px 0 0 4px}
.flight_results>ul:nth-of-type(1)>li:nth-of-type(2){color:#89868d;}
.flight_results>ul:nth-of-type(1)>li:nth-of-type(3){float: right;}

.flight_results>ul:nth-of-type(2){background-color:#e0dedf;padding: 6px 10px;border-radius: 4px;float: left;width: 100%;margin:5px 0 12px;}
.flight_results>ul:nth-of-type(2)>li{list-style: none;float: left;}
.flight_results>ul:nth-of-type(2)>li:nth-of-type(2){float: right;}
.flight_results>span{float: right;padding: 4px}

.result_area{width: 100%;float: left;}
.result_left_area{float: left;width: 18%;border-radius: 2px; box-shadow: 0 2px 4px 0 rgba(0,0,0,.2); background-color: #ffffff;}
.result_left_area .head_area{background-color: #e0dedf;float: left;width: 100%;padding:5px;margin-bottom: 4px}
.result_left_area .head_area>ul>li{list-style: none;float: left;}
.result_left_area .head_area>ul>li:nth-of-type(2){float: right;}
.result_left_area .head_area>ul>li:nth-of-type(2) {cursor: pointer;}
.result_left_area>h4{padding:7px 0 7px 25px;background-color: #e0dedf;font-weight: 500;clear: both;font-size: 14px;position:relative;}
.result_left_area>h4>a{float: right;padding-right:5px;}
.result_left_area>h4:before{content: '\f0d7';position: absolute;top:2px;left:7px;font-size: 20px;font-family: 'FontAwesome';color: #000}
.result_left_area>ul>li{list-style: none;padding: 4px 0 4px 29px}

.result_right_area{float: right;width: 81%;border-radius: 5px; margin-left: 1%;text-align: center;  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2); background-color: #ffffff;}
.result_right_area .head_area{ width: 100%; display: table; table-layout: fixed;background-color: #d3d3d3;}
.result_right_area .head_area>ul{display: table-row;}
.result_right_area .head_area>ul>li{display: table-cell; padding: 10px; text-align: center;}
.result_right_area .head_area>ul>li i{display: none;}
 
.result_right_area .main_container button{padding:8px 12px;background-color: red; font-size: 12px;color:white; border-radius:12px;border:none;border:2px solid white;}
.submitbtn input[type=button]{padding:10px 20px;margin-top:10px; width: 100%; background-color: red; font-size: 23px;color:white; border-radius:12px;border:none;border:2px solid white;}
.result_right_area .main_container button:hover{border:2px solid black;}
.pnr_button button{padding:10px 20px;margin-top:10px; width: 100%; background-color: red; font-size: 23px;color:white; border-radius:12px;border:none;border:2px solid white;}
/* .result_right_area .head_area>ul>li.departure{width: 10%;margin-left: 15%}
.result_right_area .head_area>ul>li.arrival{width: 10%;}
.result_right_area .head_area>ul>li.duration{width: 10%;margin-left: 5%}
.result_right_area .head_area>ul>li.pub_price{width: 10%;}
.result_right_area .head_area>ul>li.price_other{width: 13%;margin:0 7% 0 6%}
.result_right_area .head_area>ul>li.tds{width: 10%;} */

.result_right_area .flight_dtl>div>ul{width:100%; padding:8px; float:left;}
.result_right_area .flight_dtl>div>ul>li{list-style: none;}
.result_right_area .flight_dtl>div>ul>li>ul>li{list-style: none;float: left;font-size: 12px;padding: 6px 2px}

.result_right_area .flight_dtl>div>ul>li>ul>li.flight_name>ul>li{list-style: none;float: left;font-weight: 700}
.result_right_area .flight_dtl>div>ul>li>ul>li.flight_name>ul>li>img{width: 90%}
.result_right_area .flight_dtl>div>ul>li>ul>li.from_to{color: #6e707c}

.result_right_area .flight_dtl>div>ul>li>ul>li.price{color: #6e707c}
.result_right_area .flight_dtl>div>ul>li>ul>li.other_price{width: 24%;color: #6e707c;}
.result_right_area .flight_dtl>div>ul>li>ul>li.book_now{width: 13%;color: #6e707c}
.result_right_area .flight_dtl>div>ul>li>ul>li.travel_time>p{margin-bottom: 2px}
.travel_time .seat_left{color: #ff0000}
.result_right_area .flight_dtl>div>ul>li>ul>li.other_price>ul>li{list-style: none;display: inline-block;padding: 2px 5px;margin:2px;border-radius:12px;border:1px solid #ccc;}

.result_right_area .flight_dtl>div>ul>li>ul.footer_area>li{width: 100%;color: #e9a216;font-size: 14px; margin-top: 4px;padding: 5px;text-align: left;border-bottom: 1px solid #cccccc}


/*------------------------passenger details form area---------------------------*/

.flight_process{background-color: #fbfbfb;float: left;border: #e0e0e2 1px solid;width: 100%;margin-top: 15px;padding:8px 20px;}
.flight_process ul li{list-style: none;float: left;font-size: 14px;color: #cbcbcc;padding-left: 20px;font-weight: 700;}
.flight_process ul li:hover{color: rgb(248, 171, 3);}
.flight_process ul li:hover span{background-color: rgb(248, 171, 3);}
.flight_process ul li span{background-color:#cbcbcc;color: #fff;padding: 3px 8px;margin-right: 5px;border-radius: 4px;}
.flight_process ul{float: left;margin-bottom: 0;}
.header_selecter{float: right;text-decoration: underline;}


.left_form{width: 75%;clear: both;float: left;}
.flight_time_date_details{float: left;width: 100%;margin-top: 5px;}
.flight_time_date_details ul li{list-style: none;float: left;font-weight: 600; background-color: #ffffff; padding:15px 0 15px ;font-size: 14px;width: 25%;border: #cccccc 1px solid;text-align: center;min-height: 75px}
.flight_time_date_details ul li i{font-size:30px;margin: 0 10px;}
.flight_time_date_details ul li:first-child{padding-top: 5px;border-left: 1px solid #cccccc;}
.flight_time_date_details ul li span{padding-right: 5px;color: rgb(49, 49, 175);}

.passenger_details_note{float:left;margin:10px 0;width: 100%;background-color:#e0dedf;padding: 20px; border-radius: 4px;}
.passenger_details_note h2{font-size: 15px;float: left;font-weight: 500; margin:0 }
.passenger_details_note h3 a{font-size: 15px;float: right;font-weight: 600;color: rgb(82, 80, 80);text-decoration:underline ;}
.passenger_details_note p{font-size: 13px;clear: both; margin: 0;}

.form_content{float: left;background-color: #ffffff; width: 100%;}
.form_content h2{font-size: 17px;float: left;font-weight: 500; padding: 15px; margin:0}
.form_content h2 span{font-size: 14px;color:rgb(82, 80, 80) ;}
.form_content h3 a{font-size: 16px;float: right;font-weight: 500;  padding: 15px 8px;}

.form_content form ul{width: 100%;}
.form_content form ul  li label{font-weight: 400;font-size: 13px;color: #474646;}
.form_content form ul li{ width: 100%;display: grid;grid-template-columns: 37% 63%;align-items: center;}
/* .form_content form ul  li input,select{font-size: 12px;color: #4e4e4e !important;}
.form_content form ul  .firstName select{ width: 17%; padding: 5px;}
.form_content form ul  .firstName input{width: 45%;  margin-left: 5px ;padding: 5px; outline: none; border: 1px solid silver; color: black;
} */
/* .form_content form ul  .Gender select{width: 30%;  margin-left: 19px;}
.form_content form ul  .Date select{width: 18%;    margin-right: 4px;}
.form_content form ul  .Date label{margin-right: 71px;}
.form_content form ul .address .inputchange{margin-left: 112px;} */
/* .form_content form ul .address input {
  width: 64%;
  margin-left: 13px;
  margin-bottom: 10px;
  padding: 5px;
  outline: none;
  border: 1px solid silver;
  color: black;
}
.form_content form ul .lastname input{width: 55%;  margin-left: 38px ;padding: 5px; outline: none; border: 1px solid silver; color: black;}

.form_content form ul .city input{width: 64%;  margin-left: 38px ;padding: 5px; outline: none; border: 1px solid silver; color: black;}

.form_content form ul .country select{width: 55%;  margin-left: 48px ;padding: 5px; outline: none; border: 1px solid silver; color: black;}

.form_content form ul .email input{width: 55%; margin-left: 64px; ;padding: 5px; outline: none; border: 1px solid silver; color: black;}

.form_content form ul .mobilenumber input{width: 55%; margin-left: 9px;padding: 5px; outline: none; border: 1px solid silver; color: black;}
.form_content .text h3 {
  background: #dddddd;
  width: 100%;
  color: #184985;
  padding: 9px;
  margin-bottom: 13px;
  font-size: 16px;
} */
.form_content .text h3 span{font-size: 14px;font-weight: 400; color: gray;}
/*.form_passenger_details form > ul>li .tital{width: 20%;}
.form_passenger_details form > ul>li .first_name{width: 40%;}*/
/* .form_passenger> ul>li>ul>li{width: 20%;list-style: none;float: left;}
.form_passenger> ul>li label{width: 40%;float: left;}
.form_passenger> ul>li input[type=text],.form_passenger> ul>li input[type=email]{width: 60%;float: left;}
.form_passenger> ul>li select{width: 60%;padding:5px;color: #6c757d; border:0; border-bottom: 1px solid #ced4da; border:0;  background:url(../images/arrow.png) no-repeat right center;   -webkit-appearance: none; } */
.form_passenger> ul>li .lable_side{padding-left: 20px;}

.gst_add{float:left;position: relative;margin: 10px 0 10px 30px;clear: both;}
.gst_add label{color: rgb(49, 49, 175);font-size: 16px;font-weight: 600;}
.gst_add label span{color: red;font-size: 14px;font-weight: 600;}
.gst_add input[type=checkbox]{margin-top: 7px;}

.right_details{width:24%;float: left;margin-left: 1%;position:fixed;top:40%;right:0;}
.right_details h2{font-size: 16px;font-weight: 500;background-color:#e0dedf;padding: 10px;margin-top: 6px;float: left;width: 100%; margin:5px 0 0 }
.right_details input[type=text]{width: 60%;float: left;padding: 4px;}
.right_details .btn{width:38%;float: left;margin-left: 2%;padding: 4px;}
.pcode{padding:15px 0; width:100%; float:left}

.show_details h3{color: rgb(250, 136, 7);font-size: 15px;background-color:rgb(222, 232, 240) ;padding:5px;float: left;width: 100%;}
.show_details ul li{list-style: none;width: 33.333%;float: left;padding: 10px 0;font-size: 14px;}


/*----------------------------------form5 hotel------------------*/
.hotel_search_form {width: 100%;float: left;margin: 5px 0;padding: 10px 20px;border: 2px solid rgb(231, 228, 228);}
.hotel_search_form label{width: 100%;margin: 0;font-size: 14px;}
.hotel_search_form>input[type=text]{width: 60%;float: left;background-color: rgb(245, 248, 248);}
.hotel_search_form span{background-color: rgb(233, 229, 229);padding: 5px 6px;border-radius: 25px;margin: 0 10px ;float: left;}
.hotel_search_form a{float: left;margin-top: 5px;}
.hotel_search_form a i{color: rgb(250, 136, 7);padding-right: 5px;}

.date_time_hotel{margin: 20px 0;float: left;width: 100%}
ul.date_time_hotel li{list-style: none;width: 24%;float: left;margin-right:1%;position: relative;}
ul.date_time_hotel li svg{position: absolute;right: 10px;bottom: 10px;color:rgb(250, 136, 7) ;}
ul.date_time_hotel li select,ul.room_adult li select,ul.star_rating li select{width: 100%;padding: 7px;color: #6c757d;border: 1px solid #ced4da;border-radius: 5px;}

ul.room_adult li{list-style: none;width:21%;float: left;margin-right: 5%;}
ul.star_rating{margin: 20px 0;float: left;width: 100%;}
ul.star_rating li{width: 40%;list-style: none;float: left;}
.hotel_btn{float: right;}


/*-----------------------hotel----------------*/

.left_selection{width: 30%;float: left;border: 2px solid rgb(195, 226, 252);padding-bottom: 5px;margin-top: 5px;}
.left_selection h2{font-size: 16px;padding: 10px 0 10px 10px;background-color: rgb(195, 226, 252);}
.left_selection ul{background-color: white;margin: 0 2px;}
.left_selection ul li{list-style: none;font-size: 14px;padding: 5px 0 5px 20px;background-color:rgb(240, 235, 235);margin: 5px 5px;clear: both;}
.hotel_btn2{float: right;margin: 5px 10px 0 0;}


/*------------------------bus search prveen-------------------------------------*/
li{list-style-type: none;}
.room{width: 80%;border: 1px solid #cccc;}
.bus_search{float: left;width: 100%;border: #ccc 1px solid;}
.bus_search .form-group{width: 45%;margin: 10px 15px;float: left;}
.bus_search .form-group input[type="text"]{background-color: #fbfbfb;border: grey 1px solid;}
.bus_search .form-group input[type="date"]{background-color: #fbfbfb;border: grey 1px solid;}
.bus_search .travel{margin:40px 17px;}
ul.room_adult{width: 100%;float: left;}
.changerequest {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 40, 255, 0.4);
}
.changerequest > form {
  width: 37vw;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
}
.booking_page {
  position: relative;
}
.changerequest > form > ul > li > h3 {
  background: #0b5488 ;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border-radius: 8px 8px 0 0;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}
.changerequestoptions {
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #D7DFF4;
  font-family: 'Roboto', sans-serif;
}
.changerequestoptions > select {
  border: 1px solid #e0e0e0;
  padding: 6px;
  line-height: 26px;
  width: 60%;
  background-color: white;
  font-family: 'Roboto', sans-serif;
}
.refundstatus {
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #D7DFF4;
  font-family: 'Roboto', sans-serif;
}
.refundstatus > ul > li > h3 {
  width: 100%;
  padding-top: 1%;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}
.refundstatus > ul > li {
  width: 100%;
  padding-top: 1%;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}
.refundstatus > ul > li label {
  width: 100%;
  display: block;
  font-family: 'Roboto', sans-serif;
}
.refundstatus > ul > li textarea {
  width: 100%;
  display: block;
  resize: vertical;
  font-family: 'Roboto', sans-serif;
}
.booking_page > div:nth-of-type(2) {
  font-size: 14px;
  border: 1px solid #999;
  background-color: #ffffff;
  border-radius: 3px;
  float: left;
  width: 100%;
  margin: 0 0 1em;
}
.refundstatus > ul > li > input {
  margin-right: 6px;
}

/* ----------------------Ticket Confirmation Page (bookingConfirmation.js) ----------------------------------- */


.booking_result_page{
  float : left;
  width: 100%;
}
.nav_btn{
  background-color: #899703;
  padding: 0.8% 1.3%;
  border: none;
  color:white;
  border-radius:4px;
}
.nav_btn:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}
.home_nav_button{
  margin: 10% 0 0;
  text-align: center;
}
.booking_result .message_head .error{
  color : #cc0000;
  text-align:center;
}
.booking_result .message_head{
  margin-top:4%;
  margin-bottom:4%;
}
.booking_result .message_head .success{
  color : #4BB543;
  text-align:center;
}


/*--------------------------@media(767)--------------*/
@media(max-width:767px) and (min-width:320px){
    dl, ol, ul{margin-bottom: 5px;}
.right_header_area>ul>li{padding: 0 8px;}
.right_header_area>ul>li.user_pic{height:20px; width:50px}
.logo img{max-height: 28px;}
.logo{padding: 6px;}
.right_header_area>ul>li.user_name{min-width: 80px;border-left: 1px solid #ffff;}
.left_area{width: 10%;visibility: hidden;}
.right_area{width: 87%;padding:5px 10px 5px 5px}
.flight_search_tab, .bus_search_tab{padding:0;}
.head_tab li{padding-top: 5px;display: block;}
.head_tab li a{font-weight: 600;font-size: 13px;}
.head_tab li a span{margin-right: 5px;}
.detail_head{width: 100%;}
.detail_head li{display:inline-block;padding: 3px 9px;width: 49%;border: none;}
.detail_head li a{border-bottom: 1px solid #ccc;font-size: 13px;}
.flight_search{padding: 5px;}
.flight_search_tab .form-control{padding:5px;font-size: 13px;}
.sub_area > ul:nth-of-type(1) > li{font-size: 13px;display: block;width: 99%;}
.sub_area > ul:nth-of-type(1) > li:nth-of-type(3) input, .sub_area > ul:nth-of-type(1) > li:nth-of-type(4) input{width: 100%;display: block;padding: 5px;}
.sub_area > ul:nth-of-type(1) > li:nth-of-type(3) select, .sub_area > ul:nth-of-type(1) > li:nth-of-type(4) select{width: 100%;margin-top: 11px;}
.sub_area > ul:nth-of-type(2) > li{display: block;width: 99%;margin: 0;}
ul.preferred_carrier > li{display: block;width: 92%;margin: 7px 20px;}
.ristrict_points h3{font-size: 18px;}
.ristrict_points > ul{padding: 5px 0px 5px 20px;}
.btn{font-size: 13px;}
.ristrict_points > ul > li{width: 46%;}
.form_container{width: 75%;margin-left: 17%;left: 0;}
.form_area{margin-top: 80px;}
.form_area form ul li{width: 97%;margin: 5px 2%;}
.form_area h1{font-size: 18px;padding: 0;}
.form-control{font-size: 14px;padding: 7px;}
.custom-select{border-radius: 5px;}
.hotel_search_form{padding:10px 8px;margin:0;}
.hotel_search_form label{font-size: 13px;}
.hotel_search_form > input[type="text"]{width: 100%;padding: 3px 5px;margin: 3px auto;}
.hotel_search_form span{padding: 4px 5px;margin: 3px 13px;font-size: 13px;border-radius: 18px;}
.hotel_search_form a{margin-left: 8px;}
.date_time_hotel{margin: 8px 0;}
ul.date_time_hotel li{width: 99%;}
ul.room_adult li{width: 31%;margin-right: 2%;}
ul.star_rating{margin: 10px 0;}
.plan_name{width:50%;}








}

.input_fld > li > input {border-bottom: 1px solid lightgray;border-left: none;border-right: none;border-top: none;width: 100%;font-size: 18px;padding: 10px 0;margin: 5px 0;}
.inner_fld > li {width: 49%;font-size: 16px;display: inline-block;padding: 5px 0;font-weight: 600;}
.inner_fld > li > input{font-size: 14px;}
.inner_fld > li > a{font-size: 14px;text-align: right;display: block;color: black;}


.left_side{float: left; width: 50%;}
.left_side img{width: 100%;}

/* right_side */
.right_side{float: right; width: 50%;}
.right_side .login_box{width: 70%; margin-left: auto; margin-right: auto; margin-top:25%;}
.right_side li label{display: block; padding: 5px ;}
/* logo */
.right_side .login_box .logo {width: 35%; margin: auto;}
.right_side .login_box .logo li img{width: 100%;}

.right_side li label a.forgot{float: right; color: red;}

/* form */
.right_side .form li  input[type=text], .right_side .form li input[type=password] {padding: 16px; width: 100%; border: 1px solid silver; border-radius: 10px;box-shadow: inset 4px 3px 22px rgb(231, 231, 231); color: silver;}
.right_side .form{margin-top: 70px; line-height: 2;}
.right_side .form label{color: rgb(134, 131, 131);}

.right_side .form li input[type=submit]{ background-color: red; border: none; color: white; border-radius: 7px;width: 30%; padding: 14px; margin-top: 40px;}
.right_side .form .password-box{ margin-top: 20px;}



.middle_queue {
  display: grid;
  row-gap: 7px;
  background-color: white;
  width: 75%;
  float: left;
}
.upper_details {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 15px 30px 15px 10px;
  background-color: #f6f7fb;
  border: 1px solid #eae8eb;
}
.booking_table {
  border: 1px solid #abb7c7;
  padding: 5px;
}
.booking_table tr:first-child {
  background-color: #f1f0f5;
  text-align: left;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.booking_table tr{
  background-color: #f6f7fb;
}
.booking_table table, .booking_table tr, .booking_table td, .booking_table th {
  border: 1px solid #d1ccc9;
  border-collapse: collapse;
  padding: 4px;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
}
.flight_details_heading {
  font-size: 15px;
  padding: 11px 18px;
  background-color: #dfe4f7;
}
.upper_bar {
  border: 1px solid #eae8eb;
}
.upper_bar > ul > li:last-child {
  text-align: end;
}
.upper_bar ul {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 11px 30px 11px 10px;
  background-color: #f6f7fb;
}
.upper_details ul:last-child {
  display: grid;
  text-align: end;
}
.upper_details > ul > li > h4 {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}
.upper_details > ul > li > p {
  font-size: 13px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 18px;
}
.upper_bar > ul > li > h4 {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  line-height: 18px;
}
.upper_details ul {
  display: grid;
}
.upper_bar > ul > li > a {
  text-decoration: none;
  font-size: 13px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}
.upper_details > ul > li > h5 {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}
.Passenger_heading_main > div > ul {
  display: grid;
  grid-template-columns: 50% 50%;
}
/* .Passenger_heading_main ul li:nth-child(2), .Passenger_heading_main ul li:nth-child(4) {
  justify-self: end;
} */
.passenger_heading {
  font-size: 15px;
  padding: 10px 30px 10px 10px;
  background-color: #dfe4f7;
  font-family: 'Roboto', sans-serif;
}
.Passenger_heading_main {
  border: 1px solid #abb7c7;
  padding: 5px;
}
.Passenger_heading_main > div > ul {
  border: 1px solid #d1ccc9;
  padding: 4px;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  line-height: 28px;
}
.Passenger_heading_main > div > h3 {
  font-size: 15px;
  padding: 10px 30px 10px 10px;
  background-color: #f6f7fb;
}
.listing td {
  padding: 3px;
}
.booking_listing_right {
  width: 25%;
  float: left;
}
.booking_listing_right > h3 {
  font-size: 14px;
  padding: 10px 30px 10px 10px;
  background-color: #dfe4f7;
  font-family: 'Roboto', sans-serif;
}

.listing {
  border: 1px solid #eae8eb;
  padding: 5px;
}
.listing > table > tbody > tr:first-child > td {
  border-bottom: 1px solid #e9e9ea;
}
.listing table {
  background-color: #fcfcfc;
}
.listing table:first-child {
  padding-bottom: 6px;
}
.listing table:nth-child(1) td, .listing table:nth-child(2) td {
  width: 33.33%;
}
.listing table:nth-child(3) td{
  width: 33.33%;
}
.listing > div > h3 {
  font-size: 14px;
  padding: 8px 30px 8px 10px;
  background-color: #F0F0F0;
  font-family: 'Roboto', sans-serif;
}
.listing > div > div {
  border: 1px solid #e9e9ea;
  padding: 5px;
  background-color: white;
}
.listing > div {
  margin-top: 5px;
}
.listing > div.heading_total{
  margin-top: 0;
  border-bottom: 1px solid #abb7c7;
}
.listing > div.total_fare{
  margin-top: 0;
}
.listing > div.heading_total_pub > h3 {
  font-size: 14px;
  padding: 8px 30px 8px 10px;
  background-color: #dfe4f7;
  font-family: 'Roboto', sans-serif;
}
.listing > div.heading_total_pub {
  margin-top: 0;
}
.listing > div.heading_total > h3 {
  font-size: 14px;
  padding: 8px 30px 8px 10px;
  background-color: #dfe4f7;
  font-family: 'Roboto', sans-serif;
}
.booking_table table{width: 100%;}
.listing table{width: 100%;}

.booking_list > ul {
  display: grid;
}
.booking_list > ul > li:nth-child(2) {
  display: grid;
  grid-template-columns: 50% 50%;
}
.booking_list {
  display: grid;
  grid-template-columns: 70% 30%;
  font-size: 14px;
  font-weight: 300;
}
.booking_list > ul:nth-child(2) {
  display: grid;
  grid-template-columns: 50% 50%;
}
.booking_list h5 {
  font-size: 14px;
}
.booking_listing {
  font-size: 14px;
  border: 1px solid #999; background-color: #ffffff;
  border-radius: 3px;
  float: left;
  width: 100%;
  margin: 0 0 1em;
}
.booking_list > ul > li {
  padding: 10px;
}
.booking_list > ul:nth-child(2) {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  margin: 10px;
  background: rgba(243,243,243,.3);
  border-radius: 8px;
  padding: 3% 2%;
  border: 1px solid #DEE4F6;
  grid-template-rows: 28px 28px 28px;
  line-height: 14px;
}
.booking_list > ul:first-child > li {
  border-top: 1px solid #e7e7e7;
}
.booking_listing > ul > li {
  padding: 10px;
}
.booking_listing > ul > li:first-child {
  border-top: 1px solid #e7e7e7;
}
.booking_list > ul:nth-child(2) > li {
  padding: 0;
}
.booking_listing > ul > li:first-child > span {
  font-weight: 500;
}
.booking_listing > ul > li:last-child > ul > li:last-child > a {
  text-decoration: none;
  color: white;
  background-color: #246197;
  border-radius: 3px;
  padding: 6px 10px;
}
.booking_listing > ul > li:last-child {
  text-align: right;
  background-color: #EAF0F8;
}
.booking_listing > ul > li:last-child > ul {
  display: inline-flex;
  gap: 10px;
  margin-left: auto;
}
.booking_listing > ul > li:last-child > ul > li:first-child > a {
  background-color: #2ba030;
  border-radius: 3px;
  padding: 6px 10px;
  text-decoration: navajowhite;
  color: white;
}

/* view ticket */
.ticket_wrp {
  border: 1px solid #D6D8E7;
  margin: 20px auto;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

.top_section {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding: 10px 20px;
  font-size: 14px;
}

.tdetail ul:first-child li:first-child {
  font-weight: 600;
}

.tdetail ul:nth-child(2) {
  margin-top: 15px;
}

.tname {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.tpnr {
  text-align: right;
}

.passenger_dtls {
  padding: 10px 20px;
}

.passenger_dtls table {
  width: 100%;
  border: 1px solid #D6D8E7;
}

.passenger_dtls table tbody tr th {
  text-align: left;
  background: #004684;
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #D6D8E7;
  color: #fff;
}

.passenger_dtls table tbody tr td {
  text-align: left;
  padding: 10px 4px;
}

.passenger_dtls table tbody tr {
  display: grid;
  grid-template-columns: 33.3333% 33.3333% 33.3333%;
  font-size: 14px;
}

.flight_dtls {
  padding: 10px 20px;
}

.flight_dtls table {
  width: 100%;
  border: 1px solid #D6D8E7;
  margin-bottom: 15px;
}

.flight_dtls table tbody tr th {
  text-align: left;
  background: #004684;
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #D6D8E7;
  color: #fff;
}

.flight_dtls table tbody tr td {
  text-align: left;
  padding: 10px 4px;
}

.flight_dtls table tbody tr {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  font-size: 14px;
}

.space {
  padding-left: 10px;
}

.payments_dtls {
  margin: 10px 20px;
  border: 1px solid #D6D8E7;
}

.payments_dtls h3 {
  text-align: left;
  background: #004684;
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #D6D8E7;
  color: #fff;
}

.paymt_info {
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 14px;
}

.pymt_table table {
  margin-left: auto;
}

.pymt_table table tbody tr td {
  width: 140px;
  text-align: right;
}

.pymt_table span {
  font-weight: 500;
}

.pymt_txt span {
  font-weight: 500;
}

.pymt_txt {
  margin: 0 20px;
  display: grid;
  align-items: center;
  width: 380px;
}

.tinformation {
  margin: 10px 20px;
  padding: 30px 0 20px;
}

/* invoice */

.invoice_wrp {
  border: 2px solid #9CBAD6;
  margin: 20px auto;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

.invc_img {
  border-bottom: 1px solid #9CBAD6;
  padding: 4px;
}

.invoice_dlts {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding: 4px;
  font-size: 16px;
  border-bottom: 1px solid #9CBAD6;
}

.incnum ul li {
  font-weight: 500;
}

.invdate {
  text-align: center;
  font-weight: 400;
}

.invdate span {
  font-weight: 500;
}

.invpnr {
  text-align: right;
}

.invpnr span {
  font-weight: 500;
}

.inv_user_dtls {
  display: grid;
  grid-template-columns: 35% 30% 35%;
  padding: 4px;
  font-size: 16px;
}

.inv_user1 ul li {
  font-size: 14px;
}

.inv_user1 ul li span {
  font-weight: 600;
}

.inv_user2 {
  text-align: center;
}

.inv_user2 span {
  font-weight: 600;
}

.inv_user3 {
  font-size: 14px;
  text-align: right;
}

.inv_user3 ul li span {
  font-weight: 600;
}

.inv_ticket_dlts {
  padding: 4px;
}

.inv_ticket_dlts table {
  width: 100%;
  border: 1px solid #D6D8E7;
}

.inv_ticket_dlts table tbody tr th {
  background: #EAE9FF;
  font-weight: bold;
  color: black;
  font-size: 13px;
}

.inv_ticket_dlts table tbody tr td {
  color: black;
  font-size: 13px;
  border: 1px solid #f3f3f7;
}

.note_section>table {
  width: 100%;
}

.note_section>table>tbody>tr {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 10px 20px;
  font-size: 16px;
}

.inv_note {
  padding: 4px;
}
.details_gst{
  padding: 4px;
}
.passenger_gst{
  padding: 4px;
}
.details_gst table {
  padding: 10px 0;
}
.details_gst table tr th{
  font-size: 14px;
  padding: 4px;
}
.details_gst table tr td{
  font-size: 12px;
  padding: 4px;
}
.passenger_gst table {
  padding: 10px 0;
}
.passenger_gst table tr th{
  font-size: 14px;
  padding: 4px;
}
.passenger_gst table tr td{
  font-size: 12px;
  padding: 4px;
}
.terms_condition {
  padding: 10px 4px;
}
.terms_condition table{
  padding: 10px 4px;
}

/* BOOKING QUEUE */
.middle_queue {
  display: grid;
  row-gap: 7px;
  background-color: white;
}
.upper_details {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 15px 30px 15px 10px;
  background-color: #f6f7fb;
  border: 1px solid #eae8eb;
}
.booking_table {
  border: 1px solid #abb7c7;
  padding: 5px;
}
.booking_table tr:first-child {
  background-color: #f1f0f5;
  text-align: left;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.booking_table tr{
  background-color: #f6f7fb;
}
.booking_table table, .booking_table tr, .booking_table td, .booking_table th {
  border: 1px solid #d1ccc9;
  border-collapse: collapse;
  padding: 4px;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
}
.flight_details_heading {
  font-size: 15px;
  padding: 11px 18px;
  background-color: #f6f7fb;
}
.upper_bar {
  border: 1px solid #eae8eb;
}
.upper_bar > ul > li:last-child {
  text-align: end;
}
.upper_bar ul {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 11px 30px 11px 10px;
  background-color: #f6f7fb;
}
.upper_details ul:last-child {
  display: grid;
  text-align: end;
}
.upper_details > ul > li > h4 {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}
.upper_details > ul > li > p {
  font-size: 13px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  line-height: 18px;
}
.upper_bar > ul > li > h4 {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  line-height: 18px;
}
.upper_details ul {
  display: grid;
}
.upper_bar > ul > li > a {
  text-decoration: none;
  font-size: 13px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}
.upper_details > ul > li > h5 {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}
.Passenger_heading_main > ul {
  display: grid;
  grid-template-columns: 50% 50%;
}

.invoice_wrp {
  border: 2px solid #9CBAD6;
  margin: 20px auto;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

.invc_img {
  border-bottom: 1px solid #9CBAD6;
  padding: 4px;
}

.invoice_dlts {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding: 4px;
  font-size: 16px;
  border-bottom: 1px solid #9CBAD6;
}

.incnum ul li {
  font-weight: 500;
}

.invdate {
  text-align: center;
  font-weight: 400;
}

.invdate span {
  font-weight: 500;
}

.invpnr {
  text-align: right;
}

.invpnr span {
  font-weight: 500;
}

.inv_user_dtls {
  display: grid;
  grid-template-columns: 35% 30% 35%;
  padding: 4px;
  font-size: 16px;
}

.inv_user1 ul li {
  font-size: 14px;
}

.inv_user1 ul li span {
  font-weight: 600;
}

.inv_user2 {
  text-align: center;
}

.inv_user2 span {
  font-weight: 600;
}

.inv_user3 {
  font-size: 14px;
  text-align: right;
}

.inv_user3 ul li span {
  font-weight: 600;
}

.pass_dtls {
  padding: 5px 0;
}

.pass_dtls table {
  width: 100%;
}

.pass_dtls table,
.pass_dtls tr,
.pass_dtls td,
.pass_dtls th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
  font-size: 13px;
}

.pass_dtls table {
  text-align: left;
}

.pass_dtls tr:first-child {
  background: #EAE9FF;
}

.gst_details {
  padding: 5px 0;
  font-weight: bold;
}

.gst_details p {
  padding: 5px 0;
  font-weight: bold;
}

.gst_details table {
  width: 100%;
}

.gst_details table,
.gst_details tr,
.gst_details td,
.gst_details th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
  font-size: 13px;
}

.gst_details table {
  text-align: left;
}

.gst_details tr:first-child {
  background: #EAE9FF;
}

.passenger_details {
  padding: 5px 0;
}

.passenger_details p {
  padding: 5px 0;
  font-weight: bold;
}

.passenger_details table {
  width: 100%;
}

.passenger_details table,
.passenger_details tr,
.passenger_details td,
.passenger_details th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
  font-size: 13px;
}

.passenger_details table {
  text-align: left;
}

.passenger_details tr:first-child {
  background: #EAE9FF;
}

.terms_condition{
  padding: 5px 0;
}
.terms_condition p {
  padding: 5px 0;
  font-weight: bold;
  color: red;
}

.terms_condition th {
  text-align: left;
}
.middle_tble table:first-child {
  float: left;
  width: 20%;
  margin-right: 30%;
}
.middle_tble table:last-child {
  float: right;
  width: 40%;
  margin-left: 10%;
  background: #F4F6FB;
}
.middle_tble {
  padding: 5px 0;
}

.middle_tble table,
.middle_tble tr,
.middle_tble td,
.middle_tble th {
  padding: 4px;
  font-size: 13px;
}

.middle_tble table {
  text-align: left;
}
.middle_tble {
  padding: 8px 0;
  float: left;
  width: 100%;
}
.bold{
  font-weight: bold;
}
.bord_b td{
  border-bottom: 1px dashed black;
}
.gross_table tr td:last-child{
  text-align: right;
}
.passenger_dtls tr:first-child {
  background: #004684;
  font-weight: bold;
  color: #fff;
}
.passenger_dtls tr:first-child th {
  padding: 5px;
  text-align: left;
  width: 33.3%;
}
.passenger_dtls tr:last-child td {
  padding: 5px;
  text-align: left;
  width: 33.33%;
}

.flight_dtls tr:first-child {
  background: #004684;
  font-weight: bold;
  color: #fff;
}
.flight_dtls tr:first-child th {
  padding: 5px;
  text-align: left;
  width: 25%;
}
.flight_dtls tr:last-child td {
  padding: 5px;
  text-align: left;
  width: 25%;
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<,booking review>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.Booking_review_container_fluid{width: 100%; max-width: 1926px; margin: auto;}
.Booking_review_container_fluid .booking_review_main{width: 80%; margin: auto;}
.booking_review_main .Booking_review_process{width: 100%; position: relative;}
.booking_review_main .Booking_review_process ul { display: flex; justify-content: space-between;margin: 16px 0px 27px 0px;}
.booking_review_main .Booking_review_process ul .process_icon { padding: 6px 5px 5px 7px; border-radius: 33px; font-size: 18px;width: 73%;text-align: center;background-color: white; margin-left: 4px;}

.booking_review_main .Booking_review_process ul li .process_text {font-size: 12px; color: grey;}
.booking_review_main .Booking_review_process ul li:first-child .process_text { color: orangered;}
.booking_review_main  .booking_details h2 {font-size: 29px; margin: 10px 0px 13px 0px; font-weight: 400;}
.booking_review_main  .booking_details .details{width: 100%; display: flex;justify-content: space-between;    border: 1px solid #c9c0c0;box-shadow: 0px 0px 6px 0px #bab0b0; overflow: hidden; background-color: white};

.booking_review_main  .booking_details .details .flite_details{width: 20%; text-align: center;padding: 27px 10px 50px 10px;position: relative;}
.booking_review_main  .booking_details .details .departure{width: 20%; text-align: start; padding: 27px 10px 50px 36px;}
.booking_review_main  .booking_details .details .durations{width: 40%;text-align: center; padding: 44px 10px 50px 10px; position: relative;}
.booking_review_main  .booking_details .details .destination{width: 20%;text-align:start;padding: 27px 10px 50px 26px;}
.booking_review_main  .booking_details .details .durations .type { width: 68%;display: flex; margin: auto;justify-content: space-between; position: relative;}
.booking_review_main  .booking_details .details .departure h2{font-size: 21px; margin: 0; }
.booking_review_main  .booking_details .details .departure p{font-size: 12px;font-weight: 500;}

.booking_review_main  .booking_details .details .destination h2{font-size: 21px;  margin: 0;}
.booking_review_main  .booking_details .details .destination p{font-size: 12px; font-weight: 500; }
.booking_review_main  .booking_details .details .departure h5 {
  font-size: 11px;
  font-weight: 400;
}
.booking_review_main  .booking_details .details .destination h5 {
  font-size: 11px;
  font-weight: 400;
}
.booking_review_main .booking_details .details .flite_details .sidebox {
  border-radius: 16px;
  transform: rotate(-90deg);
  left: -37px;
  top: 61px;
  background-color: #e7e6e6;
  padding: 9px;
  position: absolute;
}
.booking_review_main .booking_details .details .flite_details .emptycircle1 {
  width: 19px;
  color: #e3e3e3;
  border-radius: 10px;
  left: 157px;
  top: -10px;
  position: absolute;
  background-color: #e3e3e3;
}
.booking_review_main .booking_details .details .flite_details .emptycircle2 {
  width: 19px;
  color: #e3e3e3;
  border-radius: 10px;
  left: 157px;
  top: 166px;
  position: absolute;
  background-color: #e3e3e3;
}
.booking_review_main .booking_details .details .flite_details .line {
  transform: rotate(-90deg);
  width: 130px;
  height: 0.1px;
  top: 89px;
  left: 100px;
  position: absolute;
  background-color: #b0a8a8;
}
.booking_review_main .Booking_review_process .line1 {
  width: 1061px;
  top: 21px;
  z-index: -1;
  left: 9px;
  position: absolute;
  height: 1px;
  background-color: #c2bcbc;
}
.fa-briefcase{color: #26268a;}
.fa-suitcase{color: #26268a;}

.booking_review_main  .booking_details .details .durations h3{font-size: 16px;
  background: white;
  width: 80px;
  margin-left: 112px;
  font-weight: 400;
  margin-top: 11px;}
.booking_review_main  .booking_details .details .durations p{margin-top: 4px;}
.booking_review_main  .booking_details .details .durations .fa-plane{ font-size: 17px;margin-right: 8px;}
.booking_details{width: 74%;}
.booking_det{display: flex;justify-content: space-between;}
.booking_det .Fare_details{width: 25%;}
.booking_det .Fare_details .main_text{display: flex; justify-content: space-between;margin: 29px 10px 10px 10px;}
.booking_det .Fare_details  .amount {background-color: white; border: 1px solid #c9c0c0;box-shadow: 0px 0px 6px 0px #bab0b0;}
.booking_det .Fare_details  .amount .Adtxt , .tax, .NetPay{display: flex; justify-content: space-between;padding: 6px;}
.booking_det .Fare_details  .amount .NetPay{background-color: #f6f4f4;  font-size: 15px;
font-weight: 500;}
.booking_det .Fare_details  .amount .Adtxt p, .tax p, .NetPay p{margin-left: 9px;}
.booking_det .Fare_details  .amount .Adtxt h2, .tax h2, .NetPay h2{margin-right: 9px;font-weight: 400;font-size: 16px;}
.booking_det .Fare_details  .amount .Adtxt , .tax{border-bottom:1px solid #dfd9d9;}
.booking_det .Fare_details .main_text  h2 {font-size: 17px; font-weight: 500;}
.TermAndCOndition {
  background: white;
  display: flex;
  border: 1px solid #dbd9d9;
  justify-content: space-between;
  margin-top: 10px;
  width: 74%;
}
.TermAndCOndition .terms {
  display: flex;
  padding: 20px 10px 10px 13px;
}
.TermAndCOndition .terms p{margin-left: 10px;}
.TermAndCOndition .terms p span{color: red;}
.TermAndCOndition input[type="submit"] {
  padding: 9px;
  width: 19%;
  box-shadow: -2px 3px 5px 0px grey;
  font-weight: 400;
  color: white;
  font-size: 20px;

  margin: 7px;
  border: none;
  background-color: #fb5d20;
}
.TermAndCOndition .terms input[type="checkbox"] {
  margin-top: 2px;
}
.travller_info h1 {
  margin-top: 22px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 400;
}

  .travller_info .details{width: 74%; background-color: white; margin-bottom: 29px;    padding-bottom: 1px;}
  .travller_info .details h2 {
    font-size: 22px;
    font-weight: 500;
    padding-top: 16px;
    margin-bottom: 16px;
    margin: 2px 0 18px 0;
    color: #004684;
    margin-left: 117px;
}
  .travller_info .details span{
    font-size: 14px;
    margin-left: 5px;
    color: #f41818;}

  .travller_info .details .contact form input[type="text"],input[type="number"],input[type="email"]{
    padding: 5px;
    outline: none;
    width: 100%;
    border: 1px solid silver;
    color: silver;
}
.travller_info .details .contact{width: 80%;margin-top: 14px;margin-left: 117px;}
.travller_info .details .contact form label{display: block; font-size: 11px; font-weight: 500; margin-bottom: 6px;}
.travller_info .details .contact form ul {display:flex; justify-content: space-between;}
.travller_info .details .contact form ul li{width: 31%;}

.travller_info .details .user_detail form input[type="text"],input[type="number"],input[type="email"],select{
  padding: 5px;
  outline: none;
  width: 100%;
  border: 1px solid silver;
  color: rgb(0, 0, 0);
}

.travller_info .details .user_detail form input{outline: none;}
.travller_info .details .user_detail{width: 80%;margin-top: 24px; display: flex;}
.travller_info .details .user_detail p {margin-left: 23px; margin-top: 26px; font-weight: 500;
font-size: 13px; width: 11%;}
.travller_info .details .user_detail form {width: 76%; margin-left: 23px;}
.travller_info .details .user_detail form label{display: block; font-size: 11px; font-weight: 500;  margin-bottom: 6px; }
.travller_info .details .user_detail form ul {display:flex; justify-content: space-between;}
.travller_info .details .user_detail form ul li{width: 31%;}
.travller_info .details .GstOption {
  width: 80%;
  margin-bottom: 27px;
  background-color: rgba(255, 205, 68, 0.842);
  color: blue;
  padding: 6px;
  font-weight: 500;
  margin-left: 117px;
  margin-top: 27px;
}

/* home-banner */
.home_banner {
  float: left;
  width: 100%;


}

.sub_area ul li label {
  width: 100%;
  display: block;
  text-transform: capitalize;
  font-weight: 400;
  padding: 0px 0 5px 0;
}

.sub_area ul li input {
  /* border: 2px solid #a3a3a3; */
  width: 100%;
  outline: none;
  /* padding: 12px; */
  border-radius: 2px;
  text-transform: capitalize;
}

.sub_area ul li input:focus{border-color: #ea2330;}
.sub_area ul li input::placeholder {
  color:#8e8e8e;
}

input#datetimepicker4 {
  width: 100%;
  outline: none;
}

input#datetimepicker4 {
  color:#8e8e8e;
}

.searchSuggession {
	background-color: #ffffff;
	box-shadow: 0 0 8px rgba(0,0,0,0.1);
	margin-top: 3px;
	position: absolute;
	top: 65px; width: 100%;
	left: 0;
	z-index: 100;
}
.searchSuggession li {
	border-bottom: #ccc 1px solid;
	padding: 10px;
	font-size: 14px;
}
.searchSuggession li:hover {
	background-color: #f1e9d7;
}

.sub_area ul li select {
  border: 2px solid #a3a3a3;
  width: 100%;
  outline: none;
  padding: 11px;
  border-radius: 2px;
  text-transform: capitalize;
}
.sub_area ul li select:focus{border-color: #ea2330;}
.sub_area ul li select::placeholder {
  color:#8e8e8e;
}

.flight_btn{
  padding: 14px 32px;
  margin-top: 15px;
  left: calc(50% - 150px);
  color: #fff;
  font-size:18px; font-weight: 700;
  border: none;
  border-radius: 4px;
  background-color: #ea2330;

}
.flight_btn:hover{background-color: #000; cursor: pointer;} 
.home_banner h4 {
	text-align: center;
	padding-top: 40px;
	color: #000;
	font-size: 26px;
	font-weight: 400;
	padding: 35px 0 15px;
}
.logo h1 {
  font-size: 30px;
  font-weight: 700;
}
.right_header_area > ul > li > a {
  text-decoration: none;
  font-size: 16px; color: #000;
}

.passenger_btn{background-color: red;padding: 2%;width:100%;color:white;}

.nav_menu{ float:right; font-size: 14px;  }
.nav_menu>ul{padding:0;margin:0}
.nav_menu>ul>li{list-style-type:none;display:block;float:left;position:relative; padding-right: 15px;}
.nav_menu>ul>li>a{color:#000000;padding:12px 15px 12px 15px;text-decoration:none;position:relative;display:block; border-radius:2px}
.nav_menu>ul>li:hover>a{color:#F00}
.nav_menu>ul>li.menu-item-has-children:hover>a{background-color:#F00; color:#ffffff}
.nav_menu>ul>li>ul{display:none;position:absolute;width:280px;background-color:#fff;left:0;top:45px;z-index:99900;margin:0;box-shadow:0 0 15px rgba(0,0,0,.6);border-top:#F00 5px solid}
.nav_menu>ul>li>ul>li:first-child>a,.nav_menu>ul>li>ul>li>ul>li:first-child>a,.nav_menu>ul>li>ul>li>ul>li>ul>li:first-child>a,.nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li:first-child>a{border-top:0}
.nav_menu>ul>li:hover>ul{display:block}
.nav_menu>ul>li>ul>li{width:100%;float:left;position:relative;list-style-type:none;border-bottom:#ccc 1px solid}
.nav_menu>ul>li>ul>li>ul>li>ul>li>a,.nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li>a{font-size:13px;border-bottom:#374f8f 1px solid;font-weight:400;text-decoration:none}
.nav_menu>ul>li>ul>li>a{color:#000;font-size:13px;font-weight:400;padding:12px 10px;display:block;text-decoration:none;text-transform:capitalize}
.nav_menu>ul>li>ul>li>a:hover{color:#F00;}
.nav_menu>ul>li>ul>li>ul{display:none;position:absolute;width:280px;background-color:#fff;left:280px;top:0px;z-index:99900;margin:0; }
.nav_menu>ul>li>ul>li:hover>ul{display:block;}
.nav_menu>ul>li>ul>li>ul>li{width:100%;float:left;position:relative;list-style-type:none;border-bottom:#ccc 1px solid}
.nav_menu>ul>li>ul>li>ul>li>a{color:#000;font-size:13px;font-weight:400;padding:12px 10px;display:block;text-decoration:none;text-transform:capitalize}
.nav_menu>ul>li>ul>li>ul>li>ul{display:block;position:absolute;left:280px;top:0;z-index:999;width:100%;margin:0;padding:0}
.nav_menu>ul>li>ul>li>ul>li:hover>ul{display:block}
.nav_menu>ul>li>ul>li>ul>li>ul>li{width:100%;float:left;position:relative}
.nav_menu>ul>li>ul>li>ul>li>ul>li>a{color:#9a0034;padding:7px 10px;display:block}

.nav_menu>ul>li>ul>li>ul>li>ul>li>ul{display:none;position:absolute;background-color:#274082;left:250px;top:0;z-index:999;width:250px;margin:0;padding:0}
.nav_menu>ul>li>ul>li>ul>li>ul>li:hover>ul{display:block}
.nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li{width:100%;float:left;position:relative}
.nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li>a{color:#9a0034;padding:7px 10px;display:block}
.nav_menu>ul>li>ul>li>ul>li>ul>li>ul>li>a:hover{background-color:#55bce9;color:#FFF}
.nav_menu>ul>li.menu-item-has-children>a{position:relative; padding-right:25px}
.nav_menu>ul>li.menu-item-has-children>a:before{position:absolute;top:14px;right:8px;content:'\f107';font-family:FontAwesome}
.nav_menu>ul>li>ul>li.menu-item-has-children>a:before{position:absolute;top:16px;right:18px;content:'\f105';font-family:FontAwesome}
.nav_menu>ul>li.menu-item-12,.nav_menu>ul>li.menu-item-254,.nav_menu>ul>li.menu-item-262,.slider_block{position:relative}
.nav_menu>ul>li.menu-item-12 ul,.nav_menu>ul>li.menu-item-254 ul,.nav_menu>ul>li.menu-item-262 ul{width:270px}
.nav_menu>ul>li.menu-item-12 li,.nav_menu>ul>li.menu-item-254 li,.nav_menu>ul>li.menu-item-262 li{width:100%}
.nav_menu>ul>li.menu-item-12 li a,.nav_menu>ul>li.menu-item-254 li a,.nav_menu>ul>li.menu-item-262 li a{font-weight:400}
.nav_menu ul li.wallet_balance{font-weight: 400; color: #000;  padding: 12px 10px 0 0;}
.nav_menu ul li.wallet_balance span{color: red;}
input#datetimepicker4 {
  width: 49%;
  outline: none;
  margin-right: 2%;
}
input#datetimepicker4:last-child {
 margin-right: 0;
  outline: none;
}

.travller_info .details .GstOption .fa{color: rgb(219, 129, 10); margin-left: 5px; margin-right: 5px;}
.booking_review_main .final_amount { display: flex;background: white; width: 74%; justify-content: space-between;    margin-bottom: 20px;}
.booking_review_main .final_amount button{padding: 9px;
  width: 19%;
  box-shadow: -2px 3px 5px 0px grey;
  font-weight: 400;
  color: white;
  font-size: 20px;
  margin: 7px;
  border: none;
  background-color: #fb5d20;
}
.booking_review_main .final_amount  h2 {
  font-weight: 500;
  font-size: 22px;
  color: green;
  margin: 14px 0 10px 22px;
}
/* confirmation */
.confirm_fluid{max-width: 1920px; width: 100%; margin: auto; background-color: #ebe7e7;}
.confirm_fluid .confirm_container{ width: 75%; margin: auto; }
.confirm_fluid .confirm_container .main{width: 70%; background-color: #ffffff;margin-bottom: 29px; padding-bottom: 15px;}
.confirm_fluid .confirm_container .main .Tickit_details{width: 90%; margin: auto; background-color: #ffffff ;    padding-top: 22px;}
.confirm_fluid .confirm_container .main .Tickit_details .text {
    width: 100%;
    background-color:#ebe7e7;
}
.confirm_fluid .confirm_container .main .Tickit_details .text  p {
    padding: 4px;
    font-weight: 500;
    font-size: 16px;
}
.confirm_fluid .confirm_container .main .Tickit_details .print .print_icon{
    width: 10%;

}
.confirm_fluid .confirm_container .main .Tickit_details .print .print_icon  .fa{
    width: 57%;
    padding: 7px;
    margin: 5px 10px 4px 10px;
    border-radius: 22px;
    text-align: center;
    border: 2px solid orange;
}
.confirm_container .main .Tickit_details .print .print_icon .fa{color: orange;}
.confirm_container .main .Tickit_details .print {
    display: flex;
    padding: 12px 0px 13px 0px;
}
.confirm_container .main .Tickit_details .print .text{width: 70%; background-color: white;}
.confirm_container .main .Tickit_details .print .text h3 {
    font-size: 14px;
    font-weight: 500;
    color: #285998;
}
.confirm_container .main .Tickit_details .print .text p {
    padding: 0px;
    font-weight: 500;
    font-size: 10px;
}
.confirm_container .main .Tickit_details .print .submit {
    text-align: center;
    width: 29%;
}
.confirm_container .main .Tickit_details .print .submit  button {
    color: white;
    margin-top: 7px;
    box-shadow: 0px 4px 4px 0px #dbd8d8;
    border: none;
    background: orange;
    padding: 7px;
    width: 80%;
}
.confirm_container .main .Tickit_details .travel_info h3{
      padding: 4px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    background-color: #ebe7e7;
}
.confirm_container .main .Tickit_details .travel_info .travler_info { display: table; clear: both; width: 100%; padding: 16px 0 10px 0;}
.main .Tickit_details .travel_info .travler_info ul {display: table-row; display: block; float: left;}
.main .Tickit_details .travel_info .travler_info li {
    display: table-cell;
    display: block;
    border-bottom: 1px solid #ebe7e7;
    font-weight: 300;
    font-size: 12px;
    padding: 5px;
}
.main .Tickit_details .travel_info .travler_info li:last-child{border: none;}
.main .Tickit_details .travel_info .travler_info ul:first-child{width: 50%;}
.main .Tickit_details .travel_info .travler_info ul:nth-child(2){width: 50%;}
.main .Tickit_details .payment_info p{
     padding: 4px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    background-color: #ebe7e7;}
    .main .Tickit_details .payment_info{margin-top: 10px;}
    .main .Tickit_details .payment_info .info{margin-top: 10px;}
    .main .Tickit_details .payment_info .info p{font-size: 10px;background-color: white; font-weight: 400;}
 .text_mi {   font-size: 18px;  padding: 12px;}
.confirm_container  .services {   width: 27%;}
.confirm_container  .services  .upper{background-color: white;    padding: 14px;}
 .confirm_container  .services  .upper h4 {  font-size: 16px; font-weight: 400;}
.confirm_container  .services  .upper p { margin-top: 12px ;font-size: 12px;}
.confirm_container .services .upper h3 { font-weight: 400;  font-size: 21px; margin-top: 10px; margin-left: 3px; }
.confirm_container .services .upper h3 .fa{ color: orange; }
.fhty {width: 100%;display: flex;  justify-content: space-between;  }
.confirm_container  .services  .upper  p:last-child{margin-left: 20px;    margin-top: 0;}
.confirm_container  .services  .bottom{background-color: white;  margin-top: 27px;  padding: 14px;}
 .confirm_container  .services  .bottom  .hotel_icon .text h4 {font-size: 16px; font-weight: 400;}
 .confirm_container  .services  .bottom  .hotel_icon{display: flex; margin-top: 23px;}
 .confirm_container  .services  .bottom  .hotel_icon > p{width: 19%;}
 .confirm_container  .services  .bottom  .hotel_icon .text{margin-left:10px;}
 .confirm_container  .services  .bottom  .hotel_icon .text h3{font-size: 13px;color: #00009e;font-weight: 600;}
 .confirm_container  .services  .bottom  .hotel_icon .text p{    font-weight: 400;
  font-size: 13px;}
  .confirm_container  .services  .bottom >h4{color: #004684; font-size: 18px; font-weight: 500;}
.confirm_fluid .confirm_container .services  .bottom  .fa{
  width: 67%;
  padding: 7px;
  color: orange;
  margin: 5px 10px 4px 10px;
  border-radius: 22px;
  text-align: center;
  border: 2px solid orange;
}
.Gst-fluid-container{width: 100%; max-width: 1926px; margin: auto;}
.Gst-fluid-container .container_main {
  width: 80%;
  padding-top: 31px;
  background-color: #fafafa;
  margin: auto;
  box-shadow: 0px 0px 18px #b5b5b5;
  overflow: hidden;
  border: 1px solid #a7a7a7;
}
.Gst-fluid-container .container_main form {display: flex; justify-content: center;}
.Gst-fluid-container .container_main form ul{width: 45%; float: left; margin-left: 18px;}
.Gst-fluid-container .container_main form ul  li label{display: block; font-weight: 400; font-size: 13px;}

h1.ppsonalDetails {
  text-decoration: underline;
  font-size: 2.2rem;
  color: #ae4e0a;
  text-shadow: 7px 1px 6px #b4b2b2;
  margin: 15px auto;
  width: 22%;
}

.Gst-fluid-container .container_main form ul  li .GstfirstName{width: 100%; overflow: hidden; display: flex;}
.Gst-fluid-container .container_main form ul  li .GstfirstName li:first-child{width: 35%; float: left;}
.Gst-fluid-container .container_main form ul  li .GstfirstName li{margin-bottom: 0;}
.Gst-fluid-container .container_main form ul  li .GstfirstName li:nth-child(2){width: 65%; float: right;}
.Gst-fluid-container .container_main form ul  li .GstfirstName li:nth-child(2) input{width: 80%;}
.Gst-fluid-container .container_main form ul  li .GstfirstName li select{width: 80%; margin: auto;}
.Gst-fluid-container .container_main  form ul  li{margin-bottom: 14px;}


.Gst-fluid-container .container_main  form ul  li input,select{font-size: 14px;color: #4e4e4e !important; width: 88%; padding: 11px;outline: none; border: 1px solid rgb(202, 201, 201); 
  border-radius: 3px;}

input#myfile {
  background: white;
  border: 1px solid #b0b0b0;
}
.Gst-fluid-container .container_main form ul  li .GstfirstName ul{width: 100%;  margin-left: 0;}
.Gst-fluid-container .container_main .proof .line {
  width: 1061px;
  width: 90%;
  margin: 21px auto;
  height: 1px;
  background-color: #adadad;
}
  .Gst-fluid-container .container_main  .proof h1 {
    font-size: 23px;
    text-shadow: 3px 3px 4px #a09f9f;
    margin: 13px 11px 15px 53px;
    color: #141c5f;
}
.paymentDetails_fluid{width: 100%; max-width: 1926px; margin: auto;}
.paymentDetails_fluid .paymentDetails_Main {
  width: 80%;
  padding-left: 103px;
  margin: auto;
  box-shadow: 0px 0px 14px #afafaf;
  border: 1px solid;
}
.checDate{display: flex;}
.checDate label{display: inline;}
input#checkNumber {
  width: 24%;
  margin: 5px;
  margin-left: 98px;
}
input#amount {
  width: 16%;
  margin-left: 149px;
}
.paymentDetails_fluid .paymentDetails_Main select {
  width: 12%;
  margin-left: 107px;
}
.paymentDetails_fluid .paymentDetails_Main p {
  font-size: 16px;
  font-weight: 500;
  margin: 16px 0;
}
.paymentDetails_fluid .paymentDetails_Main  .checDate label.labletop {
  margin-top: 10px;
}
.paymentDetails_fluid .paymentDetails_Main  .checDate label.lableleft{ margin:auto 25px;}
.paymentDetails_fluid .paymentDetails_Main label {
  font-size: 13px;
  color: #747474;
  font-weight: 400;
}
.paymentDetails_fluid .paymentDetails_Main  input#bank {width: 24%; margin: 4px; margin-left: 164px; }
.paymentDetails_fluid .paymentDetails_Main  input#Ourbank{
  margin-left: 136px;
  width: 24%;
}
.paymentDetails_fluid .paymentDetails_Main  input#bankTrancationId{
  margin-left: 59px;
  width: 24%;
}
textarea#w3review{
  margin-left: 138px;
}
.paymentDetails_fluid .paymentDetails_Main ul li{padding-bottom: 10px;}
.paymentDetails_fluid .paymentDetails_Main label.remarkLable{
  margin-top: 10px;
}
.paymentDetails_fluid .paymentDetails_Main input,select,textarea{outline: none;}
.paymentDetails_fluid .paymentDetails_Main ul li span{color: rgb(247, 18, 18);}
.paymentDetails_fluid .paymentDetails_Main h3 {
  font-size: 16px;
  margin: 17px auto;
  font-weight: 400;
  color: rgb(255, 81, 0)
}
.Gst-fluid-container .container_main ul li h3 {
  font-size: 16px;
  font-weight: 500;
}
.Gst-fluid-container .container_main .submit{
  width: 60%;
  text-align: center;
  margin:46px auto;
}
.Gst-fluid-container .container_main .submit button {
  width: 19%;
  font-size: 21px;
  padding: 9px;
}
.Gst-fluid-container .container_main .submit button:first-child{
  background-color: white; border: none; margin-right: 10px;border: 1px solid gray;box-shadow:0px 2px 5px 0px rgba(0,0,0,0.14) , 0px 1px 10px 0px rgba(0,0,0,0.12) , 0px 2px 4px -1px rgba(0,0,0,0.2) ; border-radius: 3px;}

  .Gst-fluid-container .container_main .submit button:nth-child(2) {
    background-color: #ec8d10;
    border-radius: 3px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.14) , 0px 1px 10px 0px rgba(0,0,0,0.12) , 0px 2px 4px -1px rgba(0,0,0,0.2) ;
    border: 1px solid white;
    color: white;
    margin-left: 10px;
}
.Gst-fluid-container .container_main .schekc{display: flex;  margin: 17px 27px 15px 49px;}
.Gst-fluid-container .container_main .schekc p{margin-left: 10px;}

.markuplist{width: 80%;margin: auto; }
.markuplist .markup_main ul{display: flex;     margin: 12px 0px;}
.markuplist .markup_main  ul li{width: 33%; }
.markuplist .markup_main {
  width: 70%;
  border-radius: 10px;
  padding: 17px 34px;
  overflow: scroll;
  height: 607px;
  border: 2px solid #1231ad;
}
.markuplist .text ul{display: flex; }
.markuplist .text ul li {
  width: 33%;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.markuplist .text {
  width: 70%;
}
.registration_main{width: 100%; margin: auto;}
.registration_main .Registration_form{width: 60%; margin: auto; background-color: #ffffff;}
.registration_main .Registration_form form{width: 80%; margin: auto;}
.registration_main .Registration_form form ul li{ margin: 16px auto;}
.registration_main .Registration_form form input[type="text"], input[type="number"], input[type="email"], input[type="password"] {
  width: 100%;
  padding: 13px 0px 13px 6px;
  outline: none;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  font-size: 14px;
}
.registration_main .Registration_form form ul li:nth-child(3){display: flex;}
.registration_main .Registration_form form ul li:nth-child(3) select{width:20%; border-radius: 5px 0px 0px 5px;}
.registration_main .Registration_form form ul li:nth-child(3) input[type="number"]{ border-radius: 0px 5px 5px 0px;}

.registration_main .Registration_form >p{
  font-size: 38px;
  text-align: center;
  color: #b03838;
  font-weight: 400;
  padding: 16px 10px 17px 10px;
}
.registration_main .Registration_form form input:focus {
  background-color: #e2faff;
  box-shadow: none;
  color: #464646;
}
.registration_main .Registration_form form ul li:nth-child(7){display: flex;         margin: 30px auto 6px;}
.registration_main .Registration_form form ul li:nth-child(7) p {
  font-weight: 600;
  font-size: 14px;
  margin: -3px 5px 10px 17px;
}
.registration_main .Registration_form form ul li input[type="submit"] {
  background-color: #ec8d10;
  border-radius: 3px;
  padding: 13px;
  width: 25%;
  font-size: 20px;
  font-weight: 300;
  box-shadow: -2px 7px 10px #999999;
  border: 1px solid white;
  color: white;
  margin-left: 10px;
}
.registration_main .Registration_form form ul li:nth-child(8){margin: 1px;}
.registration_main .Registration_form h3 {
  font-size: 17px;
  font-weight: 400;
  margin: 28px 92px 10px;
}
.registration_main .Registration_form h4 {
  text-decoration: underline;
  text-align: center;
  margin-top: 14px;
  font-size: 17px;
  font-weight: 500;
  color: #2000cf;
}
.Login_banner{
  width: 33%;
  background-color: #5c0202;
  float: left;
}
.Login_banner img {
  opacity: 0.73;
  max-width: 100%;
  display: block;
}
.app-login-box{
  width: 67%;
  float: right;
}

.app-login-box .login-box {
  width: 80%;
  margin: 10px auto 0 auto;
}
.app-login-box .login-box .form-row {
  display: flex;
  padding: 21px 0px;
  border-bottom: 1px solid #d0cece;
  border-top: 1px solid #d0cece;
  flex-wrap: wrap;
  justify-content: space-between;
}
.app-login-box .login-box .form-row  .col-md-6 { width: 49%;}
.app-login-box .login-box .form-row .col-md-6 label {
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #424242;
  margin: 5px 6px;
}
.app-login-box .login-box .form-row  .col-md-6  input#OTP{
  width: 100%;
  padding: 13px 0px 13px 6px;
  outline: none;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  font-size: 12px;
}
.app-login-box .login-box .form-row  .col-md-6:nth-child(3){
  margin-top: 16px;
}
.app-login-box .login-box .form-row  .col-md-6  input{border-radius: 2px;font-size: 12px;}
.app-login-box h4.mb-0{
  font-size: 19px;
    font-weight: 400;
    color: #494747;
    margin: 21px 0;
}
#proceed{
  display: none;
}
.searchSuggession > ul > li {
  width: 100%;
  background-color: #f4f4f4;
  margin: 0;
}

.form_content form ul li > div {
  display: flex;
  gap: 10px;
}
.form_content > form > ul {
  display: grid;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
  width: 100%;
  row-gap: 20px;
  padding: 20px;
  align-items: center;
}
.form_content form ul li select {
  height: 34px;
  padding: 0;
  width: 100%;
}
.form_content form ul li input {
  height: 35px;
  padding: 0;
}
.form_content form ul li input,
.form_content form ul li select,
.form_content form ul li textarea{
  border: 2px solid #a3a3a3;
} 

.form_content form ul li input[type="text"]:focus,
.form_content form ul li input[type="email"]:focus,
.form_content form ul li input[type="date"]:focus,
.form_content form ul li input[type="tel"]:focus,
.form_content form ul li input[type="number"]:focus,
.form_content form ul li select:focus,
.form_content form ul li textarea:focus{
  border: 2px solid red;
}

#alertDiv {
  color: red;
}
.details_area {
  display: flex; width: 100%;
}
.details_area > li{flex: 1; padding: 5px 10px;} 
.details_area > li:nth-child(1){text-align:left;}
.details_area > li:nth-child(4){text-align: right;}
.details_area > li:nth-child(2),.details_area > li:nth-child(3){text-align:center;}
 
.listing_block{padding-top: 30px;}
.details_area .flight_name > ul >li:nth-child(2){
  font-size: 25px;
  font-weight:500;
}
.details_area .flight_name > ul >li:nth-child(3){
  font-size:15px;
}
.details_area .from_to ul, .details_area .travel_time{font-size:15px;line-height:25px;}

.other_price > ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
 
/* .return_flight {
  display: grid;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
  width: 100%;
} */
.flights_container{
 display: flex;
 gap: 20px;
  width: 100%;
}


.listing_box{width: 100%; float: left; display: block;}
.listing_table {width: 100%; display: table; border-collapse:separate; border-spacing:0 15px;}
.listing_table > ul{display: table-row;}
.listing_table > ul > li{display: table-cell; vertical-align: top; border: #ccc 1px solid; padding: 5px;}
.listing_block{padding:30px;}
.table_grid{padding: 20px;}
.novisible{opacity: 0;}
.refundalbefare{background-color: lightgray; padding: 8px; margin-bottom: 30px;}
.main_flight_data{width: 100%; display: table; border-collapse:separate; border-collapse: collapse; table-layout: fixed;}
.main_flight_data > ul{display: table-row;}
.main_flight_data > ul > li{display: table-cell; vertical-align: middle; border: #ccc 1px solid; padding: 0 5px;}

.recharge_account{padding: 20px; text-align: center; font-weight: bold; color: red; font-size: 20px;}
.outbound{background-color: #ccc;}